<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -10 200 110"
    :width="largeur"
  >
    <title>Logo Constellation</title>
    <line
      v-for="l in lignes"
      :key="l.i"
      class="l"
      :x1="l.x1"
      :y1="l.y1"
      :x2="l.x2"
      :y2="l.y2"
    />

    <circle
      v-for="c in étoiles"
      :key="c.i"
      :class="c.cls"
      :cx="c.x"
      :cy="c.y"
    >
      <animate
        attributeName="r"
        :to="c.r"
        :dur="dur"
        :begin="c.begin"
        fill="freeze"
        repeatCount="1"
      />
    </circle>
  </svg>
</template>

<script setup lang="ts">
import {v4 as uuidv4} from 'uuid';
import {computed, ref} from 'vue';

const props = defineProps<{largeur: number; delai: number; debut: number}>();

const dur = computed(() => props.delai.toString() + 's');
const étoiles = ref(
  [
    {cls: 'cls-1', x: 70.1742129851985, y: 1.87, r: 2},
    {cls: 'cls-2', x: 67.306684713793, y: 16.47, r: 5},
    {cls: 'cls-3', x: 34.3464829934943, y: 10.01, r: 3},
    {cls: 'cls-4', x: 22.5783740121382, y: 31.93, r: 3},
    {cls: 'cls-1', x: 13.8115094092477, y: 56.91, r: 2},
    {cls: 'cls-2', x: 46.9376282583068, y: 69.76, r: 6},
    {cls: 'cls-3', x: 86.0040169410121, y: 61.32, r: 4},
    {cls: 'cls-4', x: 91.0120944854386, y: 54.13, r: 2},
    {cls: 'cls-1', x: 112.68938567, y: 63.71, r: 5},
    {cls: 'cls-2', x: 100.419159062132, y: 66.3, r: 2},
    {cls: 'cls-3', x: 88.4938654324761, y: 82.18, r: 4},
    {cls: 'cls-4', x: 97.9593284722526, y: 84.1, r: 3},
    {cls: 'cls-1', x: 69.5525695323757, y: 90.44, r: 5},
    {cls: 'cls-2', x: 64.1411605466533, y: 94.6, r: 2},
    {cls: 'cls-3', x: 157.031284111252, y: 83.37, r: 3},
    {cls: 'cls-4', x: 136.188490590752, y: 68.42, r: 5},
    {cls: 'cls-1', x: 142.952888267913, y: 53.85, r: 2},
    {cls: 'cls-2', x: 162.02953761516, y: 50.8, r: 4},
    {cls: 'cls-3', x: 185.910142776055, y: 57.71, r: 4},
    {cls: 'cls-4', x: 137.021896694756, y: 38.01, r: 5},
    {cls: 'cls-1', x: 142.973627909008, y: 29.65, r: 3},
  ].map(x => Object.assign({}, x, {begin: `${Math.random() * props.debut}s`, i: uuidv4()})),
);

const lignes = ref([
  {x1: 70.1742129851985, y1: 1.87, x2: 34.3464829934943, y2: 10.01, i: uuidv4()},
  {x1: 67.306684713793, y1: 16.47, x2: 34.3464829934943, y2: 10.01, i: uuidv4()},
  {x1: 34.3464829934943, y1: 10.01, x2: 22.5783740121382, y2: 31.93, i: uuidv4()},
  {x1: 22.5783740121382, y1: 31.93, x2: 13.8115094092477, y2: 56.91, i: uuidv4()},
  {x1: 13.8115094092477, y1: 56.91, x2: 46.9376282583068, y2: 69.76, i: uuidv4()},
  {x1: 46.9376282583068, y1: 69.76, x2: 86.0040169410121, y2: 61.32, i: uuidv4()},
  {x1: 86.0040169410121, y1: 61.32, x2: 91.0120944854386, y2: 54.13, i: uuidv4()},
  {x1: 86.0040169410121, y1: 61.32, x2: 100.419159062132, y2: 66.3, i: uuidv4()},
  {x1: 91.0120944854386, y1: 54.13, x2: 112.68938567, y2: 63.71, i: uuidv4()},
  {x1: 100.419159062132, y1: 66.3, x2: 112.68938567, y2: 63.71, i: uuidv4()},
  {x1: 112.68938567, y1: 63.71, x2: 97.9593284722526, y2: 84.1, i: uuidv4()},
  {x1: 97.9593284722526, y1: 84.1, x2: 88.4938654324761, y2: 82.18, i: uuidv4()},
  {x1: 88.4938654324761, y1: 82.18, x2: 69.5525695323757, y2: 90.44, i: uuidv4()},
  {x1: 69.5525695323757, y1: 90.44, x2: 64.1411605466533, y2: 94.6, i: uuidv4()},
  {x1: 112.68938567, y1: 63.71, x2: 136.188490590752, y2: 68.42, i: uuidv4()},
  {x1: 136.188490590752, y1: 68.42, x2: 157.031284111252, y2: 83.37, i: uuidv4()},
  {x1: 136.188490590752, y1: 68.42, x2: 142.952888267913, y2: 53.85, i: uuidv4()},
  {x1: 112.68938567, y1: 63.71, x2: 142.952888267913, y2: 53.85, i: uuidv4()},
  {x1: 142.952888267913, y1: 53.85, x2: 137.021896694756, y2: 38.01, i: uuidv4()},
  {x1: 91.0120944854386, y1: 54.13, x2: 137.021896694756, y2: 38.01, i: uuidv4()},
  {x1: 137.021896694756, y1: 38.01, x2: 142.973627909008, y2: 29.65, i: uuidv4()},
  {x1: 137.021896694756, y1: 38.01, x2: 162.02953761516, y2: 50.8, i: uuidv4()},
  {x1: 142.952888267913, y1: 53.85, x2: 162.02953761516, y2: 50.8, i: uuidv4()},
  {x1: 162.02953761516, y1: 50.8, x2: 185.910142776055, y2: 57.71, i: uuidv4()},
]);
</script>

<style>
.cls-1 {
  fill: #1697f6;
}
.cls-2 {
  fill: #7bc6ff;
}
.cls-3 {
  fill: #1867c0;
}
.cls-4 {
  fill: #aeddff;
}
.l {
  stroke: rgb(255, 255, 255);
  stroke-width: 0.5;
}
</style>
